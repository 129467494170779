import axios from 'axios'
const config = require('../../config');

const state = {
  events: []
}

const getters = {
  getEvents: state => state.events
}

const mutations = {
  setEvents: (state, events) => {
    state.events = events
  }
}

const actions = {
  async fetchEvents({ commit }, eiids) {
      let locale = this.getters.getLocale != null ? this.getters.getLocale : 'ro';
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/events&eIds=${eiids}&lang=${locale}`);
      try {
        if (resp.data.result == 1){
          resp.data.data.sort((a, b) => {
            return eiids.indexOf(a.id) - eiids.indexOf(b.id);
          });
          commit('setEvents', resp.data.data);
        }
      } catch (error) {
        console.log(error);
      }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
