<template>
    <div class="container mx-auto px-4 py-8 sm:container sm:mx-auto">
      <div class="maintitlecont" style="border-bottom: 1px solid #CDCDCD;"><h1 class="maintitle">{{$t("create_account")}}</h1></div>
      <div v-if="this.getUser == null" class="content">
          <div class="grid grid-cols-1 gap-4 mb-4">
            <div>
              <label class="block text-gray-700 font-bold mb-2" for="email">
                {{$t("email")}}
              </label>
              <input v-model.trim="register.email.value" :class="{ 'border-red': register.email.error }" class="border border-gray-400 p-2 w-full" type="email" name="email" id="email" required>
              <span v-if="register.email.error" class="alert">{{ register.email.error }}</span>
            </div>
            <div>
              <label class="block text-gray-700 font-bold mb-2" for="phone">
                {{$t("phone")}}
              </label>
              <input v-model.trim="register.phone.value" :class="{ 'border-red': register.phone.error }" class="border border-gray-400 p-2 w-full" type="text" name="phone" id="phone" required>
              <span v-if="register.phone.error" class="alert">{{ register.phone.error }}</span>
            </div>
            <div>
              <label class="block text-gray-700 font-bold mb-2" for="firstName">
                {{$t("firstName")}}
              </label>
              <input v-model.trim="register.firstName.value" :class="{ 'border-red': register.firstName.error }" class="border border-gray-400 p-2 w-full" type="text" name="firstName" id="firstName" required>
              <span v-if="register.firstName.error" class="alert">{{ register.firstName.error }}</span>
            </div>
            <div>
              <label class="block text-gray-700 font-bold mb-2" for="lastName">
                {{$t("lastName")}}
              </label>
              <input v-model.trim="register.lastName.value" :class="{ 'border-red': register.lastName.error }" class="border border-gray-400 p-2 w-full" type="text" name="lastName" id="lastName" required>
              <span v-if="register.lastName.error" class="alert">{{ register.lastName.error }}</span>
            </div>
            <div>
              <label class="block text-gray-700 font-bold mb-2" for="password">
                {{$t("password")}}
              </label>
              <input v-model.trim="register.password.value" :class="{ 'border-red': register.password.error }" class="border border-gray-400 p-2 w-full" type="password" name="password" id="password" required>
              <span v-if="register.password.error" class="alert">{{ register.password.error }}</span>
            </div>
            <div>
              <label class="block text-gray-700 font-bold mb-4" for="confirmPassword">
                {{$t("confirm_password")}}
              </label>
              <input v-model.trim="register.confirm.value" :class="{ 'border-red': register.confirm.error }" class="border border-gray-400 p-2 w-full" type="password" name="confirm" id="confimPassword" required>
              <span v-if="register.confirm.error" class="alert">{{ register.confirm.error }}</span>
            </div>
          </div>
        <div class="grid grid-cols-1 gap-4 mt-4">
          <div class="flex items-center">
              <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{$t("accept_newsletter")}}</label>
          </div>
          <div class="flex items-center">
            <input id="link-checkbox" type="checkbox" v-model="register.terms.value" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="link-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" :class="{ 'border-red': register.terms.error }">Accept <a href="#" class="text-blue-600 dark:text-blue-500 hover:underline">{{$t("terms_conditions")}}</a>.</label>
        </div>
        <div v-if="register.terms.error" class="alert">{{ register.terms.error }}</div>
        <div class="grid grid-cols-2 gap-4">
          <button @click="registerNewUser" class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center" style="border-radius: 20px; padding: 10px 20px 8px; display: block; width: 100%; justify-content: center;"><span class="mr-1">{{$t("register")}}</span></button>
          <router-link to="/login"><button class="mt-3 mb-2 font-bold px-2 py-1 flex align-center" style="border-radius: 20px; padding: 10px 20px 8px; border: 1px solid rgb(95, 46, 234); display: block; width: 100%; justify-content: center;"><span class="mr-1">{{$t("login")}}</span></button></router-link>
        </div>
        <span v-if="loginError" class="alert">{{ loginError }}</span>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        loginError: null,
        register: {
          lastName: {
            value: null,
            error: null
          },
          firstName: {
            value: null,
            error: null
          },
          email: {
            value: null,
            error: null
          },
          phone: {
            value: null,
            error: null
          },
          password: {
            value: null,
            error: null
          },
          confirm: {
            value: null,
            error: null
          },
          terms: {
            value: null,
            error: null
          }
        },
      };
    },
    computed: {
      ...mapGetters([
        'getUser'
      ])
    },
    methods: {
      ...mapActions(['registerUser']),
      async registerNewUser() {
        this.loginError = null;
        for (let field in this.register){
          this.resetError(this.register[field]);
          this.validateMandatoryField(this.register[field]);
        }
        this.validateSameValue(this.register.password, this.register.confirm);
        this.validateEmail(this.register.email);
        this.validatePhone(this.register.phone);
        this.validateTrue(this.register.terms);
        let ok = true;
        for (let field in this.register){
          if (this.register[field].error != null){
            ok = false;
            break;
          }
        }
        if (ok){
          const response = await this.$store.dispatch('registerUser', {
          email: this.register.email.value,
          password: this.register.password.value,
          firstName: this.register.firstName.value,
          lastName: this.register.lastName.value,
          phone: this.register.phone.value,
          });
          if (response.result === 1) {
            // Redirect to my-account page
            this.$router.push('/my-account');
          } else {
            // Display error message
            if (response.errors.length > 0){
              this.loginError = response.errors[response.errors.length-1];
            } else {
              this.loginError = response.errors;
            }
          }
        }
    },
    resetError(field){
        field.error = null;
    },
    validateMandatoryField(field){
      if (field.error == null && (field.value == null || (field.value instanceof String && field.value.trim().length == 0))){
        field.error = 'Câmpul este obligatoriu.';
      }
    },
    validateSameValue(field1, field2){
      if (field2.error != null){
        return;
      }
      if (field1.value != field2.value){
        field2.error = 'Parolele nu se potrivesc.';
      } else {
        field2.error = null;
      }
    },
    validateEmail(field) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const error = !emailPattern.test(field.value);
      if (error) {
        field.error = 'Email invalid.';
      }
    },
    validatePhone(field) {
      const phoneRegex = /^[0-9]{10}$/;
      const isValid = phoneRegex.test(field.value);
      if (!isValid) {
        field.error = 'Numarul de telefon nu este valid.';
      }
    },
    validateTrue(field) {
      if (!field.value) {
        field.error = 'Va rugam sa acceptati termenii si conditiile.';
      }
    }
  },
};
</script>
  
  <style scoped>

  .content {
    max-width: 500px;
    padding: 30px;
    margin: 0 auto;
  }

  input[type="text"], input[type="email"], input[type="phone"], input[type="password"] {
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    border-radius: 10px;
  }

  .alert {
    height: 48px;
    background-color: #FFC8C8;
    color: #E20000;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 40px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .border-red {
    border-color: #E20000!important;
  }

  label.border-red {
    border-bottom: 1px solid #E20000;
  }

  .alert::before {
    content: '!';
    position: absolute;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    left: 10px;
    top:13px;
    background-color: #FF0000;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #FFC8C8;
    align-items: center;
    justify-content: center;
  }

  
  .buttons {
    margin-top: 20px;
  }
  </style>
  