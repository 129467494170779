<template>
  <div class="container mx-auto px-4 md:py-8 sm:container sm:mx-auto">
    <div class="maintitlecont">
      <h1 class="maintitle font-bold">{{$t('checkout')}}</h1>
    </div>
    <HorizontalStepsDiagram :steps="steps" :activeStep="activeStep" />
    <div class="flex space-x-6 wrapper">
      <CosulMeu v-if="activeStep === 1" />
      <DetaliiUtilizator v-if="activeStep === 2" />
      <div class="w-1/4 rcol" :class="{ mtop: activeStep === 1 }">
        <div
          style="
            border: 2px solid #5f2eea;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            padding: 20px;
            background-color: #ffffff;
          "
        >
          <loading
            color="#4e46dc"
            v-model:active="loadingPay"
            transition="disappear"
            :can-cancel="false"
            :is-full-page="true"
          />
          <div
            class="mb-2"
            style="
              display: flex;
              justify-content: space-between;
              font-weight: bold;
            "
          >
            <div>{{$t('total_payment')}}:</div>
            <div>{{ this.getTotal }} Lei</div>
          </div>
          <div
            style="
              padding: 0 10px;
              text-align: left;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: start;
            "
          >
            <div class="cart-btns" style="text-align: center">
              <div v-if="activeStep === 1">
                <button
                  :disabled="getCartTickets.length == 0"
                  @click="nextStep"
                  class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
                  style="
                    border-radius: 20px;
                    padding: 10px 20px 8px;
                    display: block;
                    width: 100%;
                    justify-content: center;
                  "
                >
                  <span class="mr-1">{{$t('next_step')}}</span>
                </button>
              </div>
              <div v-if="activeStep === 2">
                <button
                  :disabled="getUser == null"
                  @click="checkoutOrder"
                  class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
                  style="
                    border-radius: 20px;
                    padding: 10px 20px 8px;
                    display: block;
                    width: 100%;
                    justify-content: center;
                  "
                >
                  <span class="mr-1">{{$t('pay')}}</span>
                </button>
              </div>
              <router-link to="/" class="other hidden lg:block">
                <button
                  class="mt-3 font-bold px-2 py-1 flex align-center"
                  style="
                    border-radius: 20px;
                    padding: 10px 20px 8px;
                    border: 1px solid #5f2eea;
                    display: block;
                    width: 100%;
                    justify-content: center;
                  "
                >
                  <span class="mr-1">{{$t('continue_shopping')}}</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HorizontalStepsDiagram from "../components/HorizontalStepsDiagram.vue";
import CosulMeu from "../components/CosulMeu.vue";
import DetaliiUtilizator from "../components/DetaliiUtilizator.vue";
import Loading from "vue-loading-overlay";
const config = require("../config");

export default {
  components: {
    HorizontalStepsDiagram,
    CosulMeu,
    DetaliiUtilizator,
    Loading,
  },
  data() {
    return {
      loadingPay: false,
      steps: [
        { title: this.$t("my_cart") },
        { title: this.$t("user_details") },
        { title: this.$t("payment") },
      ],
      activeStep: 1,
    };
  },
  computed: {
    ...mapGetters([
      "getCart",
      "getUser",
      "getTotal",
      "getInstanceIds",
      "getCartTickets",
    ]),
  },
  methods: {
    nextStep() {
      this.activeStep++;
    },
    async checkoutOrder() {
      this.loadingPay = true;
      const data = {
        transactions: [
          {
            instanceIds: this.getInstanceIds,
            deliveryId: 2,
            paymentId: 1,
          },
        ],
      };
      const response = await this.$store.dispatch("checkout", data);
      if (response.result == 1) {
        console.log(response.result);
        if (response.data.cardTransaction != null) {
          window.location.href = `${config.serverUrl}/spring/transaction/euplatesc/send?tId=${response.data.cardTransaction}`;
        }
      } else {
        console.log(response.result);
      }
      this.loadingPay = false;
    },
  },
  created() {
    this.$store.dispatch("fetchUser");
  },
};
</script> 