import axios from 'axios'
const config = require('../../config');

const state = {
  token: null,
  locale: null,
  cart: [],
  total: 0
}

const getters = {
  getToken: state => state.token,
  getLocale: state => {
    return state.locale;
  },
  getCart: state => state.cart,
  getTotal: state => state.total,
  getInstanceIds(state) {
    let instanceIds = [];
    let instances = state.cart.cart;
    if (instances == null){
      return [];
    }
    for (let instance of instances){
      instanceIds.push(instance.id);
    }
    return instanceIds;
  },
  getCartTickets(state) {
    let cartTickets = [];
    let instances = state.cart.cart;
    if (instances == null){
      return [];
    }
    for (let instance of instances){
      let ins = {
        id: instance.id,
        name: instance.name,
        dateStart: instance.date,
        dateEnd: instance.dateEnd,
        venue: instance.venueName,
        dateVisibility: instance.dateVisibility,
        venueCity: instance.venueCity,
        tickets: []
      };
      for (let category of instance.categories){
        for (let ticket of category.tickets){
          let key = category.id + '-' + (ticket.promotionId == null ? -1 : ticket.promotionId) + '-' + (ticket.fullPrice == null ? ticket.price : ticket.fullPrice);
          if (ins.tickets[key+""]){
            ins.tickets[key+""].quantity ++;
            ins.tickets[key+""].seatIds.push(ticket.seatId);
          } else {
            ins.tickets[key] = {
              name: ticket.promotionId == null ? category.name : ticket.promotion,
              price: ticket.fullPrice == null ? ticket.price : ticket.fullPrice,
              quantity: 1,
              seatIds: [ticket.seatId]
            }
          }
        }

      }
      let tkts = [];
      for (let t in ins.tickets){
        tkts.push(ins.tickets[t]);
      }
      ins.tickets = tkts;
      cartTickets.push(ins);
    }
    return cartTickets;
  }
}

const mutations = {
  setTotal: (state, total) => {
    state.total = total
  },
  setCart: (state, cart) => {
    state.cart = cart
  },
  setToken: (state, token) => {
    localStorage.setItem('token', token);
    state.token = token;
  },
  setLocale: (state, lang) => {
    localStorage.setItem('locale', lang);
    state.locale = lang;
  }
}

const actions = {
  async checkout(_, transactions){
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/cart/checkout&token=${this.getters.getToken}&data=${JSON.stringify(transactions)}`);
      return resp.data;
      //if (resp.data.result == 1){
        //commit('setCart', resp.data.data);
      //}
    } else {
      //commit('setCart', []);
    }
  },
  async getCart ({ commit }) {
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/cart/get&token=${this.getters.getToken}`);
      if (resp.data.result == 1){
        commit('setCart', resp.data.data);
      }
    } else {
      commit('setCart', []);
    }
  },
  async getTaxes ({ commit }) {
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/cart/taxes&paymentId=1&deliveryId=2&token=${this.getters.getToken}`);
      if (resp.data.result == 1){
        commit('setTotal', resp.data.data.total);
        return resp.data.data;
      }
    } else {
      commit('setTotal', 0);
    }
  },
  async selectAndAddToCart ({commit}, obj) {
    let noAddedTickets = 0;
    let selection = {};
    obj.prices.forEach(({catId, quantity}) => {
      if (selection[catId] == null) {
        selection[catId] = 0;
      }
      selection[catId] += quantity;
    });
    for(let catId in selection) {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/tickets/best&eventInstanceId=${obj.eiId}&sectionId=-1&categoryId=${catId}&number=${selection[catId]}${getTokenParam(this.getters.getToken)}`);
      if (resp.data.result == 1){
        let newToken = resp.data.token;
        if (newToken != null) {
          commit('setToken', newToken);
        }
        let tickets = resp.data.data.tickets;

        //TODO - ticket category?!?
        let addToCartObj = [];
        let index = 0;
        if (tickets.length > 0) {
          for(let price of obj.prices){
            if (catId == price['catId']){
              for (let i=0; i<price['quantity'];i++){
                if (index > tickets.length){
                  continue;
                }
                if (price['promoId'] != null){
                  addToCartObj.push({
                    sId: tickets[index].seatId,
                    pId: price['promoId']
                  });
                } else {
                  addToCartObj.push({
                    sId: tickets[index].seatId
                  });
                }
                index++;
              }
            }
          }
        }
        const addToCartResp = await axios.post(`${config.baseUrl}?address=/spring/web/add-to-cart&seats=${JSON.stringify(addToCartObj)}${getTokenParam(this.getters.getToken)}`);
        if (addToCartResp.data.result == 1){
          noAddedTickets += addToCartResp.data.data.seats.length;
        } else {
          //TODO
        }
      }
    }
    return noAddedTickets;
  },
  async removeFromCart (_, obj) {
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/web/remove-from-cart&seatIds=${obj}&token=${this.getters.getToken}`);
      if (resp.data.result == 1){
        this.dispatch("getCart");
      }
    }
  }
}

export function getTokenParam(token) {
  if (token == null){
    return "";
  } else {
    return `&token=${token}`
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}