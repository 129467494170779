<template>
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="topbar m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 justify-center items-center shadow-lg sm:shadow-none"
	>
	<router-link
			to="/"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 "
			aria-label="Muzee"
			>{{$t('museums')}}</router-link
		>
		<router-link
			v-if="getUser == null"
			to="/login"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Autentificare"
			>{{$t('login')}}</router-link
		>
		<router-link
			v-if="getUser != null"
			to="/my-account"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Contul meu"
			>{{$t('my_account')}}</router-link
		>
		<router-link
			v-if="getUser == null"
			to="/register"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Cont nou"
			>{{$t('register')}}</router-link
		>
		<LanguageSwitcher class="sm:hidden font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
	props: ['showModal', 'isOpen'],
	components: {
		LanguageSwitcher
	},
	computed: {
    ...mapGetters([
      'getUser'
    ])
  }
};
</script>

<style lang="scss" scoped></style>
