<template>
  <div
    v-for="instance in order.ceiList"
    :key="instance.id"
    class="orderbox"
  >
    <h3 class="sm-padding-10">{{ instance.name }}</h3>
    <h3>{{ formatDateFull(instance.date) }}</h3>
    <div class="name">{{ instance.venueName }}</div>
    <div v-if="order.expirationDate != null">
      Data expirare: <b>{{ formatDateFull(order.expirationDate) }}</b>
    </div>
    <button
      @click="showQR(order, instance)"
      v-if="
        order.status == 1 || order.status == 2 || order.status == 3
      "
      class="mt-4 bg-primary-muzeu text-white font-bold px-2 py-1 ebiletBtn"
      style="border-radius: 20px; padding: 10px 30px"
      
    >
      Deschide E-BILET
    </button>
    <button
      v-if="order.status == 0"
      @click="goToPayment(order)"
      class="mt-4 bg-primary-muzeu text-white font-bold px-2 py-1 payBtn"
      style="border-radius: 20px; padding: 10px 30px"
    >
      Plateste
    </button>
    <div
      class="status"
      :class="{
        'status-confirmat':
          getStatusName(order.status) == 'Confirmat',
        'status-neconfirmat':
          getStatusName(order.status) == 'Neconfirmat',
        'status-anulat': getStatusName(order.status) == 'Anulat',
      }"
    >
      {{ getStatusName(order.status) }}
    </div>
    <div class="detbtn">
      <button @click="toggleDetails(instance.id)" class="detaliiBtn">
        <svg
          :class="{ 'transform rotate-180': showDetails }"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-4 h-4 inline-block align-text-top mr-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
        {{
          isDetailsVisible(instance.id)
            ? "ascunde detalii"
            : "vezi detalii"
        }}
      </button>
    </div>
    <div class="detalii" v-if="isDetailsVisible(instance.id)">
      <div class="id pb-4">
        Id tranzactie: <b>{{ order.transactionId }}</b>
      </div>
      <div class="id pb-4">
        Valoare tranzactie:
        <b>{{ order.price }} {{ order.currency }}</b>
      </div>
      <div class="bilete">
        Bilete:
        <ul class="pt-2">
          <div
            v-for="(price, index) in ticketsObj(instance).tickets"
            :key="index"
          >
            <li class="flex justify-between items-center mb-2">
              {{ price.quantity }} bilete x {{ price.price }} RON ({{
                capitalized(price.name)
              }})
            </li>
          </div>
        </ul>
      </div>
      <div class="plata pt-2">
        Metoda de plata: <b>{{ order.payment.paymentName }}</b>
      </div>
      <div class="livrare pt-2">
        Metoda de livrare: <b>{{ order.delivery.deliveryName }}</b>
      </div>
    </div>
  </div>
</template>
  
<script>
import { formatDateFull } from "../mixins/dateformats";
import { capitalized } from "../mixins/utils";

export default {
  props: ["order", "setShowingQr", "setOrderQr", "setInstanceQr"],
  data() {
    return {
      detailsVisible: []
    }
  },
  methods: {
    formatDateFull,
    capitalized,
    getStatusName(status) {
      let name = status;
      switch (status) {
        case 0:
          name = "Neconfirmat";
          break;
        case 3:
        case 2:
        case 1:
          name = "Confirmat";
          break;
        case 4:
          name = "Anulat";
          break;
        default:
          name = status;
      }
      return name;
    },
    isDetailsVisible(orderId) {
      return this.detailsVisible.includes(orderId);
    },
    toggleDetails(orderId) {
      const index = this.detailsVisible.indexOf(orderId);
      if (index !== -1) {
        this.detailsVisible.splice(index, 1);
      } else {
        this.detailsVisible.push(orderId);
      }
    },
    ticketsObj(instance) {
      let ins = {
        id: instance.id,
        name: instance.name,
        dateStart: instance.date,
        dateEnd: instance.dateEnd,
        venue: instance.venueName,
        tickets: [],
      };
      for (let category of instance.categories) {
        for (let ticket of category.tickets) {
          let key =
            category.id +
            "-" +
            (ticket.promotionId == null ? -1 : ticket.promotionId) +
            "-" +
            (ticket.fullPrice == null ? ticket.price : ticket.fullPrice);
          if (ins.tickets[key + ""]) {
            ins.tickets[key + ""].quantity++;
            ins.tickets[key + ""].seatIds.push(ticket.seatId);
          } else {
            ins.tickets[key] = {
              name:
                ticket.promotionId == null ? category.name : ticket.promotion,
              price: ticket.fullPrice == null ? ticket.price : ticket.fullPrice,
              quantity: 1,
              seatIds: [ticket.seatId],
            };
          }
        }
      }
      let tkts = [];
      for (let t in ins.tickets) {
        tkts.push(ins.tickets[t]);
      }
      ins.tickets = tkts;
      return ins;
    },
    showQR(order, instance){
      this.setShowingQr(true);
      this.setOrderQr(order);
      this.setInstanceQr(instance);
    }
  }
}
</script>
  
<style scoped>
  .orderbox {
    border: 1px solid #b9b9b9;
    padding: 20px;
    position: relative;
    margin-bottom: 30px;
  }

  .orderbox h3 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .payBtn {
    background-color: #d30000;
  }

  .status {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  line-height: 28px;
  padding: 5px 30px;
  border-bottom-left-radius: 15px;
}

.status-confirmat {
  background-color: #5fcd56;
  color: #fff;
}

.status-neconfirmat {
  background-color: #f9f538;
  color: #111;
}

.status-anulat {
  background-color: #d30000;
  color: #fff;
}

.detalii {
  margin: 40px 0;
}

.detbtn {
  margin-bottom: 50px;
}

.detaliiBtn {
  color: #5f2eea;
  background: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  float: right;
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
}

@media only screen and (max-width: 600px) {
  .sm-padding-10 {
    padding-top: 15px;
  }
}


</style>
  