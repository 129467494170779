import axios from 'axios'
const config = require('../../config');

const state = {
  user: null,
  orders: null
}

const getters = {
  getUser: state => state.user,
  getOrders: state => state.orders
}

const mutations = {
  setUser: (state, user) => {
    state.user = user
  },
  setOrders: (state, orders) => {
    state.orders = orders
  }
}

const actions = {
  async fetchUser ({ commit }) {
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/user/profile&token=${this.getters.getToken}`);
      if (resp.data.result == 1){
        commit('setUser', resp.data.data);
      } else {
        commit('setUser', null);
      }
    } else {
      commit('setUser', null);
    }
  },
  async loginUser ({commit}, obj) {
    let query = `${config.baseUrl}?address=/spring/api/1.0.0/user/login&username=${obj.username}&password=${obj.password}&remember=${obj.remember}`;
    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    if (resp.data.result == 1){
      let newToken = resp.data.token;
      if (newToken != null) {
        commit('setToken', newToken);
      }
      this.dispatch("fetchUser");
    }
    return resp.data;
  },
  async registerUser ({commit}, obj) {
    let query = `${config.baseUrl}?address=/spring/api/1.0.0/user/register`;
    if (obj.userId != null){
      query += `&userId=${obj.userId}`;
    }
    if (obj.username != null){
      query += `&username=${obj.username}`;
    }
    if (obj.email != null){
      query += `&email=${obj.email}`;
    }
    if (obj.password != null){
      query += `&password=${obj.password}`;
    }
    if (obj.firstName != null){
      query += `&firstName=${obj.firstName}`;
    }
    if (obj.lastName != null){
      query += `&lastName=${obj.lastName}`;
    }
    if (obj.phone != null){
      query += `&phone=${obj.phone}`;
    }
    if (obj.newsletter != null){
      query += `&newsletter=${obj.newsletter}`;
    }
    if (obj.oldPassword != null){
      query += `&oldPassword=${obj.oldPassword}`;
    }
    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    if (resp.data.result == 1){
      let newToken = resp.data.token;
      if (newToken != null) {
        commit('setToken', newToken);
      }
      await this.dispatch("fetchUser");
    }
    return resp.data;
  },
  async getAllOrders ({commit}) {
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/user/orders&token=${this.getters.getToken}`);
      if (resp.data.result == 1){
        commit('setOrders', resp.data.data);
        // console.log(resp.data);
      } else {
        commit('setOrders', null);
      }
    } else {
      commit('setOrders', null);
    }
  },
  async getOrder (_, code) {
    const resp = await axios.post(`${config.baseUrl}?address=/spring/web/order&code=${code}`);
    return resp.data;
  },
  async logout({commit}) {
    if (this.getters.getToken != null) {
      const response = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/user/logout&token=${this.getters.getToken}`);
      if (response.data.result == 1){
        commit('setUser',null);
        commit('setOrders',null);
      }
      return response.data;
    }
  },
  async resetPassword (_, obj) {
    let query = `${config.baseUrl}?address=/spring/api/1.0.0/user/password/reset-link&email=${obj.email}&page=${obj.page}`;
    const resp = await axios.post(query);
    return resp.data;
  },
  async changePassword (_, obj) {
    let query = `${config.baseUrl}?address=/spring/api/1.0.0/user/password/change&email=${obj.email}&password=${obj.password}&link=${obj.link}`;
    const resp = await axios.post(query);
    return resp.data;
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}