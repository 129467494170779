<template>
  <div v-if="showingQr">
    <button @click="showingQr = false" class="mt-3 mb-2 font-bold px-2 py-1 flex align-center" style="border-radius: 20px; padding: 10px 20px 8px; border: 1px solid rgb(95, 46, 234); display: block; width: 100%; justify-content: center;"><span class="mr-1">{{ $t('back') }}</span></button>
    <TicketDetails :acOrder="orderQr" :acInstance="instanceQr"/>
  </div>
  <div v-show="!showingQr" class="container mx-auto px-4 sm:py-8 sm:container sm:mx-auto">
    <div class="maintitlecont">
      <h1 class="maintitle">{{ $t('my_account') }}</h1>
    </div>
    <div class="flex myacc">
      <!-- Sidebar -->
      <div class="w-1/4">
        <div
          class="p-4 bg-purple-800 text-white text-center text-2xl"
          style="background-color: #5f2eea"
        >
          <div class="flex justify-between items-center">
            <span class="md:text-center; md:w-full">Meniu</span>
            <button
              @click="toggleMenu"
              class="p-2 focus:outline-none md:hidden"
            >
              <i class="fa-solid fa-chevron-down"></i>
            </button>
          </div>
        </div>
        <div class="menumyaccount" :class="{ 'hidden md:block': !isMenuOpen }">
          <ul>
            <li
              class="menu-item border-b border-gray-300"
              @click="selectedMenuItem = 'comenzi'"
            >
              <span class="menu-icon"><i class="fas fa-ticket"></i></span>
              {{ $t('orders') }}
            </li>
            <li
              class="menu-item border-b border-gray-300"
              @click="selectedMenuItem = 'datePersonale'"
            >
              <span class="menu-icon"
                ><i class="fa fa-info-circle" aria-hidden="true"></i
              ></span>
              {{ $t('personal_info') }}
            </li>
            <li
              class="menu-item border-b border-gray-300"
              @click="selectedMenuItem = 'schimbaParola'"
            >
              <span class="menu-icon"><i class="fas fa-user-edit"></i></span>
              {{ $t('change_password') }}
            </li>
            <li class="menu-item" @click="logout">
              <span class="menu-icon"
                ><i class="fa fa-sign-out" aria-hidden="true"></i
              ></span>
              {{ $t('exit') }}
            </li>
          </ul>
        </div>
      </div>
      <div class="w-3/4">
        <div class="p-30" v-if="selectedMenuItem === 'comenzi'">
          <h2 class="stitle">{{ $t('orders') }}</h2>
          <div v-for="order in getOrders" :key="order.id">
            <OrderDetails :order="order" :setShowingQr="setShowingQr" :setOrderQr="setOrderQr" :setInstanceQr="setInstanceQr"/>
          </div>
          <div
            v-if="!getOrders || getOrders.length === 0"
            class="text-center py-4"
          >
          {{ $t('no_orders') }}
          </div>
        </div>
        <div class="p-30" v-else-if="selectedMenuItem === 'datePersonale'">
          <h2
            class="stitle"
            style="border-bottom: 1px solid #cdcdcd; margin-bottom: 30px"
          >
          {{ $t('personal_info') }}
          </h2>
          <div>
            <loading
              class="vl-parent"
              color="#4e46dc"
              v-model:active="detailsLoading"
              transition="disappear"
              :can-cancel="false"
              :is-full-page="false"
            />
            <div class="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div>
                <label class="block text-gray-700 font-bold mb-2" for="firstName">
                  {{ $t('firstName') }}
                </label>
                <input
                  :class="{ 'border-red': details.firstName.error }"
                  class="border border-gray-400 p-2 w-full"
                  type="text"
                  name="firstName"
                  id="firstName"
                  v-model.trim="details.firstName.value"
                  required
                />
                <span v-if="details.firstName.error" class="alert">{{
                  details.firstName.error
                }}</span>
              </div>
              <div>
                <label class="block text-gray-700 font-bold mb-2" for="lastName">
                  {{ $t('lastName') }}
                </label>
                <input
                  :class="{ 'border-red': details.lastName.error }"
                  class="border border-gray-400 p-2 w-full"
                  type="text"
                  name="lastName"
                  id="lastName"
                  v-model.trim="details.lastName.value"
                  required
                />
                <span v-if="details.lastName.error" class="alert">{{
                  details.lastName.error
                }}</span>
              </div>
              <div>
                <label class="block text-gray-700 font-bold mb-2" for="email">
                  {{ $t('email') }}
                </label>
                <input
                  :class="{ 'border-red': details.email.error }"
                  class="border border-gray-400 p-2 w-full"
                  type="email"
                  name="email"
                  id="email"
                  v-model.trim="details.email.value"
                  required
                />
                <span v-if="details.email.error" class="alert">{{
                  details.email.error
                }}</span>
              </div>
              <div>
                <label class="block text-gray-700 font-bold mb-2" for="phone">
                  {{ $t('phone') }}
                </label>
                <input
                  :class="{ 'border-red': details.phone.error }"
                  class="border border-gray-400 p-2 w-full"
                  type="text"
                  name="phone"
                  id="phone"
                  v-model.trim="details.phone.value"
                  required
                />
                <span v-if="details.phone.error" class="alert">{{
                  details.phone.error
                }}</span>
              </div>
            </div>
            <div class="grid grid-cols-1 gap-4 mt-4">
              <div class="flex items-center">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  v-model.trim="details.newsletter.value"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="default-checkbox"
                  class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >{{ $t('accept_newsletter') }}</label
                >
              </div>
            </div>
          </div>
          <button
            @click="saveDetails"
            class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 40px 8px;
              display: inline-block;
              justify-content: center;
            "
          >
            <span class="mr-1">{{ $t('save') }}</span>
          </button>
          <span v-if="detailsError" class="alert">{{ detailsError }}</span>
          <!-- <span v-if="passmsg" class="passmsg">{{ passmsg }}</span> -->
        </div>
        <div class="p-30" v-else-if="selectedMenuItem === 'schimbaParola'">
          <h2
            class="stitle"
            style="border-bottom: 1px solid #cdcdcd; margin-bottom: 30px"
          >
          {{ $t('change_password') }}
          </h2>
          <div>
            <label class="block text-gray-700 font-bold mb-4" for="password">
              {{ $t('current_password') }}
            </label>
            <input
              v-model.trim="password.value"
              :class="{ 'border-red': password.error }"
              class="border border-gray-400 p-2 w-full"
              type="password"
              name="password"
              id="newpassword"
              required
            />
            <span v-if="password.error" class="alert">{{
              password.error
            }}</span>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 md:gap-4">
            <div>
              <label
                class="block text-gray-700 font-bold mb-2 mt-4"
                for="newpassword"
              >
              {{ $t('new_password') }}
              </label>
              <input
                v-model.trim="newpassword.value"
                :class="{ 'border-red': newpassword.error }"
                class="border border-gray-400 p-2 w-full"
                type="password"
                name="newpassword"
                id="newpassword"
                required
              />
              <span v-if="newpassword.error" class="alert">{{
                newpassword.error
              }}</span>
            </div>
            <div>
              <label
                class="block text-gray-700 font-bold mb-2 mt-4"
                for="confirmPassword"
              >
              {{ $t('confirm_password') }}
              </label>
              <input
                v-model.trim="confirm.value"
                :class="{ 'border-red': confirm.error }"
                class="border border-gray-400 p-2 w-full"
                type="password"
                name="confirmPassword"
                id="confimPassword"
                required
              />
              <span v-if="confirm.error" class="alert">{{
                confirm.error
              }}</span>
            </div>
          </div>
          <button
            @click="changePass"
            class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 40px 8px;
              display: inline-block;
              justify-content: center;
            "
          >
            <span class="mr-1">{{ $t('change_password') }}</span>
          </button>
          <span v-if="loginError" class="alert">{{ loginError }}</span>
          <span v-if="passmsg" class="passmsg">{{ passmsg }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { delay } from "../mixins/utils";
import { formatDateFull } from "../mixins/dateformats";
import {
  resetError,
  validateEmail,
  validateMandatoryField,
  validatePhone,
  validateSameValue
} from "../mixins/validation";
import Loading from "vue-loading-overlay";
import TicketDetails from "../components/TicketDetails.vue"
import OrderDetails from "../components/OrderDetails.vue"

const config = require("../config");
export default {
  components: {
    Loading,
    TicketDetails,
    OrderDetails
  },
  data() {
    return {
      showingQr: false,
      orderQr: null,
      instanceQr: null,
      isMenuOpen: false,
      selectedMenuItem: "comenzi",
      password: {
        value: null,
        error: null,
      },
      newpassword: {
        value: null,
        error: null,
      },
      confirm: {
        value: null,
        error: null,
      },
      loginError: null,
      passmsg: null,
      detailsError: null,
      detailsLoading: null,
      details: {
        lastName: {
          value: null,
          error: null,
        },
        firstName: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
        phone: {
          value: null,
          error: null,
        },
        newsletter: {
          value: null,
          error: null,
        },
      },
    };
  },
  methods: {
    formatDateFull,
    goToPayment(order) {
      window.location.href = `${config.serverUrl}/spring/transaction/euplatesc/send?tId=${order.transactionId}`;
    },
    setShowingQr(value) {
      this.showingQr = value;
    },
    setOrderQr(value) {
      this.orderQr = value;
    },
    setInstanceQr(value) {
      this.instanceQr = value;
    },
    ...mapMutations(["setUser", "setOrders"]),
    async logout() {
      const resp = await this.$store.dispatch("logout");
      console.log(resp);
      if (resp.result == 1) {
        this.$router.push("/login");
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    resetError,
    validateMandatoryField,
    validateEmail,
    validatePhone,
    validateSameValue,
    ...mapActions(["changePassword"]),
    async changePass() {
      this.loginError = null;
      this.passmsg = null;
      this.resetError(this.newpassword);
      this.validateMandatoryField(this.newpassword);
      this.resetError(this.password);
      this.validateMandatoryField(this.password);
      this.resetError(this.confirm);
      this.validateMandatoryField(this.confirm);
      this.validateSameValue(this.confirm, this.newpassword);
      let ok = true;
      if (this.newpassword.error != null || this.password.error != null || this.confirm.error != null) {
        ok = false;
      }
      if (ok) {
        const response = await this.$store.dispatch("registerUser", {
          userId: this.getUser.userId,
          password: this.newpassword.value,
          oldPassword: this.password.value,
        });
        if (response.result === 1) {
          this.passmsg = "Parola a fost modificata";
        } else {
          // Display error message
          if (response.errors.length > 0) {
            this.loginError = response.errors[response.errors.length - 1];
          } else {
            this.loginError = response.errors;
          }
        }
      }
    },
    async saveDetails() {
      this.detailsError = null;
      for (let field in this.details) {
        this.resetError(this.details[field]);
        this.validateMandatoryField(this.details[field]);
      }
      this.validateEmail(this.details.email);
      this.validatePhone(this.details.phone);
      let ok = true;
      for (let field in this.details) {
        if (this.details[field].error != null) {
          ok = false;
          break;
        }
      }
      if (ok) {
        this.detailsLoading = true;
        await delay(100);
        const response = await this.$store.dispatch("registerUser", {
          userId: this.getUser.userId,
          email: this.details.email.value,
          firstName: this.details.firstName.value,
          lastName: this.details.lastName.value,
          phone: this.details.phone.value,
          newsletter: this.details.newsletter.value,
        });
        this.detailsLoading = false;
        this.copyDetails();
        if (response.result != 1) {
          this.showError = response.errors[response.errors.length - 1];
        }
      }
    },
    copyDetails() {
      this.details.email.value = this.getUser.email;
      this.details.phone.value = this.getUser.phone;
      this.details.firstName.value = this.getUser.firstName;
      this.details.lastName.value = this.getUser.lastName;
      this.details.newsletter.value = this.getUser.newsletter;
    }
  },
  computed: {
    ...mapGetters(["getUser", "getOrders"]),
  },
  async mounted() {
    try {
      //await this.$store.dispatch('fetchUser');
      this.user = this.getUser;
      if (!this.getUser) {
        this.$router.push("/login"); // Redirect to the login page
      } else {
        // console.log(this.user);
        this.copyDetails();
        await this.$store.dispatch("getAllOrders");
        this.orders = this.$store.getters.getOrders;
        console.log(this.orders);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.menu-item {
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 18px;
  color: #000000;
  transition: background-color 0.3s;
  padding: 20px;
  background-color: #fff;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #ededed;
}

.menumyaccount {
  border: 1px solid #b9b9b9;
}

.menu-icon {
  margin-right: 10px;
}

.p-30 {
  padding: 10px 27px;
}

.myacc {
  flex-wrap: wrap;
}

.stitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 34px;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: 0;
}

@media (max-width: 767px) {
  .p-30 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>