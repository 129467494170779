<template>
  <div class="w-3/4" v-if="getUser == null">
    <div class="tabform">
      <div class="tabs">
        <button
          class="tab-btn"
          :class="{ active: activeTab === 'new-user' }"
          @click="activeTab = 'new-user'"
        >
          {{ $t("register") }}
        </button>
        <button
          class="tab-btn"
          :class="{ active: activeTab === 'login-user' }"
          @click="activeTab = 'login-user'"
        >
        {{ $t("login") }}
        </button>
      </div>
      <div class="content" v-if="activeTab === 'new-user'">
      <loading
          class="vl-parent"
          color="#4e46dc"
          v-model:active="loading.register"
          transition="disappear"
          :can-cancel="false"
          :is-full-page="false"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="firstName">
              {{ $t("firstName") }}
            </label>
            <input
              v-model.trim="register.firstname.value"
              :class="{ 'border-red': register.firstname.error }"
              class="border border-gray-400 p-2 w-full"
              type="text"
              name="firstName"
              required
            />
            <span v-if="register.firstname.error" class="alert">{{
              register.firstname.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="lastName">
              {{ $t("lastName") }}
            </label>
            <input
              v-model.trim="register.lastname.value"
              :class="{ 'border-red': register.lastname.error }"
              class="border border-gray-400 p-2 w-full"
              type="text"
              name="lastName"
              required
            />
            <span v-if="register.lastname.error" class="alert">{{
              register.lastname.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="email">
              {{ $t("email") }}
            </label>
            <input
              v-model.trim="register.email.value"
              :class="{ 'border-red': register.email.error }"
              class="border border-gray-400 p-2 w-full"
              type="email"
              name="email"
              required
            />
            <span v-if="register.email.error" class="alert">{{
              register.email.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="phone">
              {{ $t("phone") }}
            </label>
            <input
              v-model.trim="register.phone.value"
              :class="{ 'border-red': register.phone.error }"
              class="border border-gray-400 p-2 w-full"
              type="text"
              name="phone"
              required
            />
            <span v-if="register.phone.error" class="alert">{{
              register.phone.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="password">
              {{ $t("password") }}
            </label>
            <input
              v-model.trim="register.password.value"
              :class="{ 'border-red': register.password.error }"
              class="border border-gray-400 p-2 w-full"
              type="password"
              name="password"
              required
            />
            <span v-if="register.password.error" class="alert">{{
              register.password.error
            }}</span>
          </div>
          <div>
            <label
              class="block text-gray-700 font-bold mb-2"
              for="confirmPassword"
            >
            {{ $t("confirm_password") }}
            </label>
            <input
              v-model.trim="register.confirm.value"
              :class="{ 'border-red': register.confirm.error }"
              class="border border-gray-400 p-2 w-full"
              type="password"
              name="confirmPassword"
              required
            />
            <span v-if="register.confirm.error" class="alert">{{
              register.confirm.error
            }}</span>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 mt-4">
          <div class="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              v-model="register.newsletter.value"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="default-checkbox"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >{{ $t("accept_newsletter") }}</label
            >
          </div>
          <div class="flex items-center">
            <input
              id="link-checkbox"
              type="checkbox"
              v-model="register.terms.value"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="link-checkbox"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              :class="{ 'border-red': register.terms.error }"
              >Accept
              <a
                href="#"
                class="text-blue-600 dark:text-blue-500 hover:underline"
                >{{ $t('terms_conditions') }}</a
              >.</label
            >
          </div>
          <div v-if="register.terms.error" class="alert">
            {{ register.terms.error }}
          </div>
          <span v-if="showError" class="alert">{{ showError }}</span>
          <div class="grid grid-cols-2 gap-4">
            <button
              @click="registerNewUser"
              class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
              style="
                border-radius: 20px;
                padding: 10px 20px 8px;
                display: block;
                width: 100%;
                justify-content: center;
              "
            >
              <span class="mr-1">{{ $t("register") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="content" v-if="activeTab === 'login-user'">
      <loading
          class="vl-parent"
          color="#4e46dc"
          v-model:active="loading.login"
          transition="disappear"
          :can-cancel="false"
          :is-full-page="false"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="email">
              {{ $t("email") }}
            </label>
            <input
              v-model.trim="login.email.value"
              :class="{ 'border-red': login.email.error }"
              class="border border-gray-400 p-2 w-full"
              type="email"
              name="email"
              required
            />
            <span v-if="login.email.error" class="alert">{{
              login.email.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="password">
              {{ $t("password") }}
            </label>
            <input
              v-model.trim="login.password.value"
              :class="{ 'border-red': login.password.error }"
              class="border border-gray-400 p-2 w-full"
              type="password"
              name="password"
              required
            />
            <span v-if="login.password.error" class="alert">{{
              login.password.error
            }}</span>
          </div>
          <button
            @click="loginUser"
            class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 20px 8px;
              display: block;
              width: 100%;
              justify-content: center;
            "
          >
            <span class="mr-1">{{ $t("login") }}</span>
          </button>
          <router-link to="/reset-password">
          <button
            class="mt-3 mb-2 font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 20px 8px;
              border: 1px solid rgb(95, 46, 234);
              display: block;
              width: 100%;
              justify-content: center;
            ">
            <span class="mr-1">{{ $t("forgot_password") }}</span>
          </button>
          </router-link>
          <span v-if="showError" class="alert">{{ showError }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="w-3/4" v-if="getUser != null">
    <div class="tabform">
      <div class="content">
        <loading
          class="vl-parent"
          color="#4e46dc"
          v-model:active="loading.saveData"
          transition="disappear"
          :can-cancel="false"
          :is-full-page="false"
        />
        <h1 class="mb-5">{{ $t("tickets_email") }}:</h1>
        <div class="md:grid grid-cols-1 md:grid-cols-2 md:gap-4">
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="email">
              {{ $t("email") }}
            </label>
            <input
              :disabled="!editInfo"
              v-model.trim="user.email.value"
              :class="{ 'border-red': user.email.error }"
              class="border border-gray-400 p-2 w-full"
              type="email"
              name="email"
              required
            />
            <span v-if="user.email.error" class="alert">{{
              user.email.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="phone">
              {{ $t("phone") }}
            </label>
            <input
              :disabled="!editInfo"
              v-model.trim="user.phone.value"
              :class="{ 'border-red': user.phone.error }"
              class="border border-gray-400 p-2 w-full"
              type="text"
              name="phone"
              required
            />
            <span v-if="user.phone.error" class="alert">{{
              user.phone.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="firstName">
              {{ $t("firstName") }}
            </label>
            <input
              :disabled="!editInfo"
              v-model.trim="user.firstname.value"
              :class="{ 'border-red': user.firstname.error }"
              class="border border-gray-400 p-2 w-full"
              type="text"
              name="firstName"
              required
            />
            <span v-if="user.firstname.error" class="alert">{{
              user.firstname.error
            }}</span>
          </div>
          <div>
            <label class="block text-gray-700 font-bold mb-2" for="lastName">
              {{ $t("lastName") }}
            </label>
            <input
              :disabled="!editInfo"
              v-model.trim="user.lastname.value"
              :class="{ 'border-red': user.lastname.error }"
              class="border border-gray-400 p-2 w-full"
              type="text"
              name="lastName"
              required
            />
            <span v-if="user.lastname.error" class="alert">{{
              user.lastname.error
            }}</span>
          </div>
          <div
            v-show="editInfo == false"
            style="
              text-align: right;
              font-weight: 600;
              display: block;
              padding: 10px;
              grid-column: span 2;
            "
          >
            <span style="cursor: pointer" @click="editInfo = !editInfo"
              >{{ $t("modify") }}</span
            >
          </div>
          <button
            v-show="editInfo"
            @click="saveData"
            class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 20px 8px;
              display: block;
              width: 100%;
              justify-content: center;
            "
          >
            <span class="mr-1">{{ $t("save") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";

export default {
  name: "DetaliiUtilizator",
  components: {
    Loading,
  },
  data() {
    return {
      loading: {
        saveData: false,
        login: false,
        register: false
      },
      activeTab: "new-user",
      register: {
        firstname: {
          value: null,
          error: null,
        },
        lastname: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
        phone: {
          value: null,
          error: null,
        },
        password: {
          value: null,
          error: null,
        },
        confirm: {
          value: null,
          error: null,
        },
        terms: {
          value: false,
          error: null,
        },
        newsletter: {
          value: false,
        },
      },
      login: {
        email: {
          value: null,
          error: null,
        },
        password: {
          value: null,
          error: null,
        },
      },
      user: {
        firstname: {
          value: null,
          error: null,
        },
        lastname: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
        phone: {
          value: null,
          error: null,
        },
        terms: {
          value: false,
          error: null,
        },
        newsletter: {
          value: false,
        },
      },
      showError: null,
      editInfo: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  created() {
    if (this.getUser != null) {
      this.copyUserData();
    }
  },
  watch: {
    getUser() {
      this.copyUserData();
    },
  },
  methods: {
    async registerNewUser() {
      this.showError = null;
      if (this.activeTab === "new-user") {
        for (let field in this.register) {
          this.resetError(this.register[field]);
          this.validateMandatoryField(this.register[field]);
        }
        this.validateSameValue(this.register.password, this.register.confirm);
        this.validateEmail(this.register.email);
        this.validatePhone(this.register.phone);
        this.validateTrue(this.register.terms);
        let ok = true;
        for (let field in this.register) {
          if (this.register[field].error != null) {
            ok = false;
            break;
          }
        }
        if (ok) {
          this.loading.register = true;
          const response = await this.$store.dispatch("registerUser", {
            username: this.register.email.value,
            email: this.register.email.value,
            firstName: this.register.firstname.value,
            lastName: this.register.lastname.value,
            password: this.register.password.value,
            phone: this.register.phone.value,
          });
          if (response.result == 1) {
            const loginResp = await this.$store.dispatch("loginUser", {
              username: this.register.email.value,
              password: this.register.password.value,
              remember: true,
            });
            if (loginResp.result != 1) {
              //should never happed
              console.log(loginResp);
            }
          } else {
            this.showError = response.errors[response.errors.length - 1];
          }
          this.loading.register = false;
        }
      }
    },
    async saveData() {
      this.loading.saveData = true;
      const response = await this.$store.dispatch("registerUser", {
        userId: this.getUser.userId,
        email: this.user.email.value,
        firstName: this.user.firstname.value,
        lastName: this.user.lastname.value,
        phone: this.user.phone.value,
      });
      if (response.result == 1) {
        this.editInfo = !this.editInfo;
      } else {
        this.showError = response.errors[response.errors.length - 1];
      }
      this.loading.saveData = false;
    },
    async loginUser() {
      this.showError = null;
      if (this.activeTab === "login-user") {
        for (let field in this.login) {
          this.resetError(this.login[field]);
          this.validateMandatoryField(this.login[field]);
        }
        this.validateEmail(this.login.email);
        let ok = true;
        for (let field in this.login) {
          if (this.login[field].error != null) {
            ok = false;
            break;
          }
        }
        if (ok) {
          this.loading.login = true;
          const response = await this.$store.dispatch("loginUser", {
            username: this.login.email.value,
            password: this.login.password.value,
            remember: true,
          });
          if (response.result != 1) {
            this.showError = response.errors[response.errors.length - 1];
          }
          this.loading.login = false;
        }
      }
    },
    copyUserData() {
      this.user.email.value = this.getUser.email;
      this.user.phone.value = this.getUser.phone;
      this.user.firstname.value = this.getUser.firstName;
      this.user.lastname.value = this.getUser.lastName;
    },
    resetError(field) {
      field.error = null;
    },
    validateMandatoryField(field) {
      if (
        field.error == null &&
        (field.value == null ||
          (field.value instanceof String && field.value.trim().length == 0))
      ) {
        field.error = "Câmpul este obligatoriu.";
      }
    },
    validateSameValue(field1, field2) {
      if (field2.error != null) {
        return;
      }
      if (field1.value != field2.value) {
        field2.error = "Parolele nu se potrivesc.";
      } else {
        field2.error = null;
      }
    },
    validateEmail(field) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const error = !emailPattern.test(field.value);
      if (error) {
        field.error = "Email invalid.";
      }
    },
    validatePhone(field) {
      const phoneRegex = /^[0-9]{10}$/;
      const isValid = phoneRegex.test(field.value);
      if (!isValid) {
        field.error = "Numarul de telefon nu este valid.";
      }
    },
    validateTrue(field) {
      if (!field.value) {
        field.error = "Va rugam sa acceptati termenii si conditiile.";
      }
    },
  },
};
</script>

<style scoped>
.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
  border-bottom: 1px solid rgb(228, 218, 255);
  cursor: pointer;
}

.tabform {
  padding: 0;
  border: 1px solid rgb(228, 218, 255);
  border-radius: 20px;
  margin-bottom: 40px;
}

.tab-btn {
  height: 47px;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-btn.active {
  background-color: #5f2eea;
  color: #fff;
}

.content {
  padding: 30px;
  min-height: 400px;
}

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="password"] {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.alert {
  height: 48px;
  background-color: #ffc8c8;
  color: #e20000;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  border-radius: 10px;
  margin-top: 10px;
}

.border-red {
  border-color: #e20000 !important;
}

label.border-red {
  border-bottom: 1px solid #e20000;
}

.alert::before {
  content: "!";
  position: absolute;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  left: 10px;
  top: 13px;
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #ffc8c8;
  align-items: center;
  justify-content: center;
}
.buttons {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .tabform label {
    margin-top: 10px;
  }
}
</style>