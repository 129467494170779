<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			copyrightText: 'muzeu.ro - powered by ',
		};
	},
};
</script>

<template>
	<div class="block mt-4 md:flex md:justify-between md:mt-0">
	<div class="flex justify-center sm:justify-start gap-4">
		<router-link
		to="/terms"
		class="font-general-medium block text-left text-md font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 "
		aria-label="Termeni si conditii"
		>{{ $t('terms') }}</router-link>
		<router-link
		to="/gdpr"
		class="font-general-medium block text-left text-md font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 "
		aria-label="Termeni GDPR"
		>{{ $t('gdpr') }}</router-link>
		<router-link
		to="/contact"
		class="font-general-medium block text-left text-md font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 "
		aria-label="Termeni GDPR"
		>Contact</router-link>
	</div>
	<div class="anpc justify-center sm:justify-start gap-4 flex mb-2">
		<a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank"><img
			src="@/assets/images/anpc-litigii1.svg"
			alt="litigii1"
		/></a>
		<a href="https://anpc.ro/ce-este-sal/" target="_blank"><img
			src="@/assets/images/anpc-litigii2.svg"
			alt="litigii2"
		/></a>
	</div>
	<div class="flex justify-center items-center text-center">
		<div
			class="font-general-regular text-md text-ternary-dark dark:text-ternary-light"
		>
			&copy; {{ copyrightDate }}
			{{ copyrightText }} 
			<a href="https://www.bilet.ro" target="_blank">BILET.ro</a>
		</div>
	</div>
</div>
</template>

<style lang="scss" scoped></style>
