<template>
  <div class="container mx-auto px-4 sm:py-8 sm:container sm:mx-auto">
    <div class="maintitlecont" style="border-bottom: 1px solid #cdcdcd">
      <h1 class="maintitle">{{$t('reset_password')}}</h1>
    </div>
    <div class="content text-center">
      <loading
        class="vl-parent"
        color="#4e46dc"
        v-model:active="loading"
        transition="disappear"
        :can-cancel="false"
        :is-full-page="false"
      />
      <div class="text-center" style="max-width: 500px; margin: auto">
        <h2>{{$t('enter_email_to_reset_password')}}</h2>
      </div>
      <div class="grid grid-cols-1 gap-4 text-left" style="margin-top: 50px">
        <div>
          <label class="block text-gray-700 font-bold mb-2" for="email">
            {{$t('email_address')}}
          </label>
          <input
            v-model.trim="login.email.value"
            :class="{ 'border-red': login.email.error }"
            class="border border-gray-400 p-2 w-full"
            type="email"
            name="email"
            id="email"
            required
          />
          <span v-if="login.email.error" class="alert">{{ login.email.error }}</span>
        </div>
        <button
          @click="resetPw"
          class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
          style="
            border-radius: 20px;
            padding: 10px 20px 8px;
            display: block;
            width: 100%;
            justify-content: center;
          "
        >
          <span class="mr-1">{{$t('reset_password_button')}}</span>
        </button>
        <span v-if="resetError" class="alert">{{ resetError }}</span>
        <span v-if="resetOk">{{$t('follow_email_instructions')}}</span>
        <router-link to="/login"
          ><button
            class="mt-3 mb-2 font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 20px 8px;
              border: 1px solid rgb(95, 46, 234);
              display: block;
              width: 100%;
              justify-content: center;
            "
          >
            <span class="mr-1">{{$t('login')}}</span>
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import {
  resetError,
  validateEmail,
  validateMandatoryField,
} from "../mixins/validation";
const config = require("../config");

export default {
  data() {
    return {
      loading: false,
      resetError: null,
      resetOk: null,
      login: {
        email: {
          value: null,
          error: null,
        },
      },
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    validateEmail,
    validateMandatoryField,
    ...mapActions(["resetPassword"]),
    async resetPw() {
      for (let field in this.login) {
        resetError(this.login[field]);
        validateMandatoryField(this.login[field]);
      }
      validateEmail(this.login.email);
      let ok = true;
      for (let field in this.login) {
        if (this.login[field].error != null) {
          ok = false;
          break;
        }
      }
      if (ok) {
        this.loading = true;
        const response = await this.$store.dispatch("resetPassword", {
          email: this.login.email.value,
          page: `${config.siteUrl}/change-password`,
        });
        if (response.result === 1) {
          this.resetOk = true;
        } else {
          // Display error message
          this.resetError = response.errors[response.errors.length - 1];
          this.resetOk = false;
        }
        this.loading = false;
      }
    },
  },
  async created() {
    try {
      //await this.$store.dispatch("fetchUser");
      this.user = this.getUser;
      if (this.getUser) {
        this.$router.push("/my-account"); // Redirect to my-account
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
  
  <style scoped>
.content {
  max-width: 500px;
  padding: 30px;
  margin: 0 auto;
}

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="password"] {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.alert {
  height: 48px;
  background-color: #ffc8c8;
  color: #e20000;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  border-radius: 10px;
  margin-top: 10px;
}

.border-red {
  border-color: #e20000 !important;
}

label.border-red {
  border-bottom: 1px solid #e20000;
}

.alert::before {
  content: "!";
  position: absolute;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  left: 10px;
  top: 13px;
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #ffc8c8;
  align-items: center;
  justify-content: center;
}

.buttons {
  margin-top: 20px;
}
</style>
  