<template>
  <div class="museumlist">
    <h1 class="text-xl md:text-3xl font-bold mb-4">{{$t('all_museums')}}</h1>

    <div class="mt-10 sm:mt-10">
			<div
				class="
					flex
					justify-between
					border-b border-primary-light
					dark:border-secondary-dark
					pb-3
					gap-2
          flex-wrap
				"
			>
				<div class="flex justify-between gap-2">
          <form @submit.prevent>
					<input
            @input="updateSearch"
						class="font-general-medium
						pl-3
						pr-1
						sm:px-4
						py-2
						border-1 border-gray-200
						dark:border-secondary-dark
						rounded-lg
						text-sm
						sm:text-md
						bg-secondary-light
						dark:bg-ternary-dark
						text-primary-dark
						dark:text-ternary-light
						"
						id="name"
						name="name"
						type="search"
						required=""
						:placeholder="$t('search')"
						aria-label="Name"
					/>
        </form>
				</div>
        <div class="flex justify-between">
          <select v-model="selectedCity" id="cityFilter"
          class="font-general-medium
				px-4
				py-2
				border-1 border-gray-200
				dark:border-secondary-dark
				rounded-lg
				text-sm
				sm:text-md
				bg-secondary-light
				dark:bg-ternary-dark
				text-primary-dark
				dark:text-ternary-light
        w-40
			">
            <option value="">{{$t('all_states')}}</option>
            <option v-for="city in cities" :key="city">{{ city }}</option>
          </select>
        </div>
			</div>
		</div>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-10 md:mt-8 md:mb-8">
      <loading class="vl-parent"
                  v-model:active="isLoading"
                  color="#4e46dc"
                 :can-cancel="false"
                 :is-full-page="false"/>
      <div
        v-for="museum in filteredMuseums" 
        :key="museum.id"
        class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
        aria-label="Single Project"
        >
          <router-link 
          :to="{ name: getMuseumRoute(), params: { slug: JSON.parse(museum.extraJson).slug }}"
          >
            <div class="m-image rounded-t-xl border-none" v-bind:style="{ backgroundImage: 'url(' + museum.images[0].url + ')', backgroundPosition: 'center' }">
            </div>
            <div class="text-center px-4 py-6">
              <p
                class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
              >
                {{ museum.title }}
              </p>
              <span
                class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
                >{{ museum.city.name }}</span
              >
            </div>
        </router-link>
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay';

export default {
  data() {
    return {
      isLoading: true,
      searchText: '',
      selectedCity: '',
      cities: [],
    };
  },
  components: {
    Loading
  },
  methods: {
    getHomepageStructuredData() {
      return {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: this.museumslist.map((museum, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@type': 'Museum',
            name: museum.title,
            description: JSON.parse(museum.extraJson).desc,
          },
        })),
      };
    },
    addStructuredData() {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(this.getHomepageStructuredData());
      document.head.appendChild(script);
    },
    updateSearch(event) {
    this.searchText = event.target.value;
    },
    getMuseumRoute() {
      return this.$router.currentRoute.value.meta.lang == 'ro' ? 'museum': 'museum-en';
    }
  },
  computed: {
    ...mapGetters([
      'getMuseums'
    ]),
    museumslist() {
      return this.$store.getters.getMuseums
    },
    filteredMuseums() {
      const filteredList = [];
      let index = 0;
      for (const museum of this.museumslist) {
        const matchesCity = !this.selectedCity || museum.city.name === this.selectedCity;
        const matchesSearch = !this.searchText || museum.title.toLowerCase().includes(this.searchText.toLowerCase());
        if (matchesCity && matchesSearch) {
          museum.id = index;
          index++;
          filteredList.push(museum);
        }
      }

      return filteredList;
    },
  },
  async created() {
    await this.$store.dispatch('fetchMuseums');
    this.isLoading = false;
    this.addStructuredData();
    this.cities = [...new Set(this.museumslist.map(museum => museum.city.name))];
  },
  beforeCreate() {
      const script = document.querySelector('script[type="application/ld+json"]');
      if (script) {
        document.head.removeChild(script);
      }
  },
}
</script>
