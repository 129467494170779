<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data() {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<div
		class="mainbann flex flex-col sm:justify-between items-center sm:flex-row bg-cover bg-center relative"
		:style="{'background-image': 'url(' + require('../../assets/images/muzeu-ro-header.jpg') + ')'}"
	>
		<div class="absolute inset-0 bg-black opacity-30"></div>
		<div class="w-full bg-opacity-50 py-4 z-10">
			<div class="container mx-auto px-4 sm:px-6 lg:px-8">
				<h1 class="text-xl md:text-4xl font-bold text-white text-center mb-4">{{$t('homepage_title')}}</h1>
				<p class="text-sm md:text-lg text-white text-center">{{$t('homepage_desc')}}</p>
			</div>
		</div>
	</div>
</template>

<style scoped>
.mainbann {
	height: 350px;
}
@media screen and (max-width:768px) {
	.mainbann {
	height: 150px;
	}
	.m-image {
		height: 250px;
	}
}
</style>
