export default {
    title: 'Romanian museums, all togheter',
    search: 'Search',
    all_states: 'All States',
    all_museums: 'Our museums',
    homepage_title: "Visit Romania's Museums",
    homepage_desc: "Click and visit Romania. All Romania's museums, all togheter",
    cart: 'Cart',
    no_tickets: 'No tickets',
    checkout: 'Checkout',
    ticket: 'ticket',
    next_step: 'Next',
    select_tickets: 'Select tickets',
    read_more: 'Show more',
    read_less: 'Show less',
    museums: 'Museums',
    login: 'Login',
    my_account: 'My account',
    register: 'Register',
    create_account: 'Create new account',
    email: 'Email',
    phone: 'Phone',
    firstName: 'Firstname',
    lastName: 'Lastname',
    password: 'Password',
    confirm_password: 'Confirm password',
    accept_newsletter: 'I want to subscribe to the newsletter',
    terms_conditions: 'terms and conditions',
    authentication: 'Authentication',
    use_form_below: 'Use the form below to log in and access your tickets',
    email_address: 'Email address',
    forgot_password: 'I forgot my password',
    new_account: 'New Account',
    enter_email_to_reset_password: 'Enter the email address of the account to reset the password',
    reset_password_button: 'Reset password',
    follow_email_instructions: 'Follow the instructions sent to your email to reset your password.',
    terms_and_conditions: 'Terms and Conditions',
    terms_intro: 'By purchasing a ticket through muzeu.ro, you agree to the following terms and conditions, which you are obliged to comply with:',
    terms_detail_1: 'the ticket cannot be exchanged or returned regardless of the reason; in the event that the event is canceled, the ticket cost will be recovered from the event organizer, muzeu.ro will make all efforts to mediate this transfer but the obligation for refunding the money lies exclusively with the organizer; for tickets purchased from partner stores, a request should be sent to office@muzeu.ro specifying the order number and attaching a copy of the tickets, in this case you might be directed to personally collect the money from the same partner store from which you purchased the tickets; we mention that regardless of the reason for the cancellation or postponement of the event, the amount to be transferred will only cover the value of the returned tickets; the value of the tickets will be refunded within a maximum of 30 days from the request. muzeu.ro cannot be held responsible for damages caused directly or indirectly to the client or other third parties by the cancellation or postponement of the event',
    terms_detail_2: 'if there are suspicions of using the muzeu.ro site by you for malicious and/or abusive purposes such as but not limited to repeatedly blocking seats without completing the order, creating multiple accounts and using them for purposes other than ticket purchasing, performing actions that can lead to excessive loading and stressing the muzeu.ro site, in these cases muzeu.ro may temporarily suspend your account up to closing it in exceptional cases. muzeu.ro will not be required to notify you of the decisions taken nor to provide explanations, these may be permanent. Furthermore, muzeu.ro reserves the right to inform the competent authorities about your actions',
    terms_detail_3: 'purchasing the ticket implies that you agree to the terms and conditions imposed by the event organizer regardless of the manner in which they are displayed or communicated and which you are obliged to comply with. It is also your obligation to inquire about them and to address questions directly to the organizer in case of uncertainties',
    terms_detail_4: 'the services offered by muzeu.ro are only for ticket sales; muzeu.ro has no control over the event or the location where the event takes place',
    terms_detail_5: 'muzeu.ro may request a processing fee which you agree to pay; this represents the necessary costs for processing your order under optimal conditions',
    terms_detail_6: 'muzeu.ro is not responsible for actions unrelated to the ticket sales procedure',
    terms_detail_7: 'muzeu.ro cannot be held responsible for entry to the event with counterfeit tickets',
    terms_detail_8: 'if tickets purchased from muzeu.ro are resold or transferred for material gain by an unauthorized third party of muzeu.ro, the event organizer, the agents responsible for supervising the location, and the representatives of muzeu.ro may refuse the ticket holders entry to the event, including their removal from the location',
    terms_detail_9: 'unless otherwise specified, the ticket is valid for access of a single person to one performance of the event mentioned on the ticket',
    terms_detail_10: 'the event organizer has the right to modify the dates of the event, the new information being announced through specific communication means;',
    terms_detail_11: 'ensure that you arrive on time at the event so that you enter the location before the start time stated on the ticket. The promoter reserves the right to deny access if the event has already started;',
    terms_detail_12: 'this ticket cannot be given as a prize in any contest without the prior agreement of the event organizer',
    terms_detail_13: 'muzeu.ro is not responsible for damages caused by third parties to any ticket holder purchased through muzeu.ro',
    terms_detail_14: 'access to the event with alcoholic beverages, drugs, hazardous substances, glass containers, photo and video equipment, blunt objects, and weapons of any kind is prohibited; ticket holders may be searched at the entrance to the location to ensure compliance with the announced rules',
    terms_detail_15: 'drug use or other illegal substances at the event location constitutes a crime and will be punished according to the legal provisions in force',
    terms_detail_16: 'restrictions in accordance with laws and regulations on the protection of minors: persons under the age of 18 are strictly prohibited from accessing events that may have a high degree of violence (such as boxing matches, etc.) and in places where alcoholic beverages are served or smoking is encouraged',
    terms_detail_17: 'purchasing the ticket implies that you agree to the terms and conditions displayed on the www.muzeu.ro website as well as the organizer’s regulations, terms, and conditions which you are obliged to comply with',
    response_payment: 'Payment response',
    payment_confirmed_congratulations: 'Payment has been confirmed. Congratulations!',
    open_e_ticket: 'Open E-TICKET',
    order_not_confirmed_error_message: 'Order could not be confirmed. Error message:',
    please_try_again_to_purchase_tickets: 'Please try again to purchase tickets.',
    museum_list: 'List of museums',
    back: 'Back',
    orders: 'Orders',
    personal_info: 'Personal information',
    change_password: 'Change password',
    exit: 'Exit',
    no_orders: 'No orders',
    save: 'Save',
    current_password: 'Current password',
    new_password: 'New password',
    delete: 'Delete',
    tickets_total: 'Tickes total',
    tax: 'Processing fees',
    total_payment: 'To pay',
    pay: 'Go to payment',
    continue_shopping: 'Select more tickets',
    tickets_email: 'We will send the tickets to this email address',
    modify: 'Modify',
    my_cart: 'My tickets',
    user_details: 'Client details',
    payment: 'Payment',
    terms: 'Terms and conditions',
    gdpr: 'GDPR terms'
}