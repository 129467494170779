import axios from 'axios'
const config = require('../../config');
const state = {
  museums: [],
}

const getters = {
  getMuseums: state => state.museums
}

const mutations = {
  setMuseums: (state, museums) => {
    state.museums = museums
  },
}

const actions = {
  async fetchMuseums ({ commit }) {
    try {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/list/get/muzee`);
      if (resp.data.result == 1){
        commit('setMuseums', resp.data.data)
      }
    } catch(error){
      console.error("Error: " +  error);
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}