<script>
import feather from 'feather-icons';
import AppHeader from './components/shared/AppHeader';
import AppFooter from './components/shared/AppFooter';
import { useStore } from 'vuex'
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex';

export default {
	components: {
		AppHeader,
		AppFooter,
	},
	setup: () => {
		const store = useStore();
		if (localStorage.getItem('token') != null) {
			store.commit('setToken',localStorage.getItem('token'));
			store.dispatch("fetchUser");
		}
    console.log(window.location.href);
    if (localStorage.getItem('locale') != null) {
			store.commit('setLocale', localStorage.getItem('locale'));
		} else {
      window.location.href.includes('/en/') ? store.commit('setLocale','en') : store.commit('setLocale','ro');
    }
	},
  computed: {
      ...mapGetters([
        'getLocale'
      ])
    },
  created() {
    console.log(this.$i18n);
    const locale = this.$store.getters.getLocale;
    this.$i18n.locale = locale

    const siteData = reactive({
      title: this.$t('title'),
      description: `Viziteaza Romania la un click distanta. Punem la un loc cele mai importante muzee din Romania.`,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    })
  },
	data: () => {
		return {
			appTheme: localStorage.getItem('theme'),
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div :class="appTheme" class="pt-0.5">
		<!-- App header -->
		<AppHeader />

		<!-- Render active component contents with vue transition -->
		<transition name="fade" mode="out-in">
			<router-view :theme="appTheme" />
		</transition>

		<!-- Scroll to top -->
		<back-to-top
			visibleoffset="500"
			right="30px"
			bottom="110px"
			class="shadow-lg"
		>
			<i data-feather="chevron-up"></i>
		</back-to-top>

		<!-- App footer -->
		<AppFooter />
	</div>
</template>

<style lang="scss">
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
}

.logo {
  max-height: 42px;
}

body {
  font-family: 'Poppins', sans-serif;
}

.mtitle {
  background-color: RGB(79 70 229);
  border-radius: 20px;
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
}

.cart-section {
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  padding: 20px;
  margin-bottom: 0px;
  position: sticky;
  top: 30px;
  background: #fff;
  z-index: 2;
  border: 2px solid #5F2EEA; 
  filter: drop-shadow(0px 4px 4px 79 70 229(0 0 0 / 25%));
}

.m-image {
  width: 100%;
  height: 300px;
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

input[type=number]::-webkit-inner-spin-button {
    display:none!important;
}

.vue-back-to-top {
  @apply p-2 bg-indigo-500 hover:bg-indigo-600 text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}

.fade-enter-active {
  animation: coming 0.4s;
  animation-delay: 0.2s;
  opacity: 0;
}

.fade-leave-active {
  animation: going 0.4s;
}

.details {
  display: none;
}

.details.selected {
  display: block;
}

a.delete {
  position: absolute;
  right: 20px;
  top:10px;
  font-weight: bold;
  text-decoration: underline;
}

.cols2grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 30px;
}

button.active {
  background-color: red;
}

.btnCart {
  border-radius: 20px;
}

.btnCart span {
  padding: 5px 30px;
}

.header {
  height: 225px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-content {
  text-align: center;
}

.header-title {
  font-size: 34px;
  font-weight: 600;
  color: #ffffff;
}

.maintitle {
  font-size: 30px;
}

.maintitlecont {
  display: flex; 
  align-items: center; 
  padding: 30px 0; 
  justify-content: center;
}

.cartcontent {
  display: grid; 
  grid-template-columns: 1fr 5fr; 
  column-gap: 20px;
}

input[type="text"], input[type="email"], input[type="phone"], input[type="password"] {
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    border-radius: 10px;
}

.topbar {
  padding-top: 10px;
}

.anpc {
  display: inline-flex;
  gap: 20px;
}

@keyframes going {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@media only screen and (max-width: 1024px) {
  .lcol {
    width: 100%!important;
  }

  .rcol {
    width: 100%!important;
    position: fixed!important;
    bottom: 0!important;
    margin: 0!important;
    left: 0!important;
    z-index: 9;
  }

  .cart-title {
    padding-right: 70px;
  }

  .cart-btns {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
  }

  .cart-btns button {
    max-width: 200px;
    margin: 0 auto;
  }

  .cols2grid {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .cart-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .cart-section h2, 
  .cartdetails {
    display: none;
  }

  .cart {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    width: 100%;
  }

  .btnCart {
    float: right;
  }

  .btnCart span {
    font-size: 12px!important;
    padding: 5px 10px;
  }

  .mcart {
    display: none;
  }

  .maintitle {
    font-size: 22px;
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .w-1\/4, .shoppingcart, .ticketslist {
    width: 100%!important;
  }
  .w-3\/4 {
    width: 100%!important;
  }

  .arr {
    display: block;
  }

  .p-30 {
    padding: 30px 0 0;
  }

  .status {
    font-size: 14px;
    padding: 0 20px;
  }

  .calendar {
    justify-content: center;
  }

  .cart-btns {
    width: 100%;
  }

  .cart-btns button {
    font-size: 14px!important;
  }

  .vue-back-to-top {
    display: none;
  }

  .step-name {
    text-align: center;
  }
  .ticket-title {
    padding-right: 70px;
  }

  .cartcontent {
    grid-template-columns: 1fr;
    row-gap: 0px;
  }

  .cartcontent> div:first-child p {
    display: inline-flex;
    padding-right: 10px;
  }

  .header-title {
    font-size: 24px;
    padding: 0 20px;
  }

  .header {
    height: 100px;
  }

  .maintitlecont {
    padding: 10px 0; 
  }

  #app {
    font-size: 14px;
  }

  .logo {
    height: 16px;
  }

}

@media only screen and (max-width: 580px) {

  .lcol .content {
    padding: 10px!important;
  }

  .cart-btns button {
    min-height: 45px;
  }
}

</style>
